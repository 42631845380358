import React, { useState, useEffect, useImperativeHandle, forwardRef, useContext } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ButtonGroup,
} from "@mui/material";
import { Delete as DeleteIcon, Edit as EditIcon, Add as AddIcon, ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { LoadingContext, UserContext } from "../../../../../context/handleContext";
import axios from "axios";
import { toast } from "react-toastify";

import SyncIcon from '@mui/icons-material/Sync';
import CloseIcon from '@mui/icons-material/Close';

const PauseUsers = forwardRef((props, ref) => {
    const [user] = useContext(UserContext);
    const [loading, setLoading] = useContext(LoadingContext);
    const [open, setOpen] = useState(false);
    const [users, setUsers] = useState([]);
    const [formData, setFormData] = useState({ numero: "", nome: "", time: "", timetype: "m", message: "" });
    const [uid, setUid] = useState(null);
    const [editOpen, setEditOpen] = useState(false);

    const update = (unique_id = undefined) => {
        if (unique_id) setLoading(true);

        axios
        .get(`https://api.allon.cloud/api/manage/integration/whatsapp/stopflow/${unique_id || uid}`, {
            headers: {
            Authorization: user.token,
            },
        })
        .then(({ data }) => setUsers(data))
        .catch((err) => {
            if (unique_id) toast.error(err?.response?.data?.message || err?.response?.data || "Erro ao listar");
        })
        .finally(() => setLoading(false));
    };

    useImperativeHandle(ref, () => ({
        open: (unique_id) => {
        setUid(unique_id);
        update(unique_id);
        setOpen(true);
        },
    }));

    useEffect(() => {
        if (!open) return;

        const interval = setInterval(() => update(), 60 * 1000);

        return () => clearInterval(interval);
    }, [open, uid]);

    const handleClose = () => {
        setOpen(false);
        setFormData({ numero: "", nome: "", time: "", timetype: "m", message: "" });
        setEditOpen(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSave = () => {
        const updatedUsers = [...users];
        const editIndex = updatedUsers.findIndex(({ numero }) => numero === formData.numero)
        
        if (editIndex !== null) updatedUsers[editIndex] = formData
        else updatedUsers.push(formData)

        setUsers(updatedUsers);
        handleUpdate(updatedUsers)
        setEditOpen(false);
    };

    const handleEdit = (index) => {
        setFormData(users[index]);
        setEditOpen(true)
    };

    const handleDelete = (index) => {
        const updatedUsers = users.filter((_, i) => i !== index);
        setUsers(updatedUsers);
        handleUpdate(updatedUsers)
    };

    const handleUpdate = (list) => {
        // setLoading(true)
        axios
        .put(`https://api.allon.cloud/api/manage/integration/whatsapp/stopflow/${uid}`, list, {
            headers: {
                Authorization: user.token,
            },
        })
        .then(({ data }) => {})
        .catch((err) => toast.error(err?.response?.data?.message || err?.response?.data || "Erro ao salvar"))
        // .finally(() => setLoading(false));
    }

    return (
        <Modal open={open} onClose={handleClose}>
        <Box
            sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 700,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            overflowY:"auto",
            maxHeight:'90vh'
            }}
        >
            <div
            style={{
                display:'flex',
                justifyContent:'space-between'
            }}
            >
                <Typography sx={{color:'text.primary'}} variant="h6" component="h2" gutterBottom>
                Usuários em Pausa
                </Typography>

                <ButtonGroup
                size="small"
                >
                    <Button
                    color='primary'
                    startIcon={<SyncIcon />}
                    onClick={() => update(uid)}
                    size="small"
                    sx={{height:'30px'}}
                    >
                        Atualizar
                    </Button>
                    <Button
                    color='error'
                    size="small"
                    startIcon={<CloseIcon />}
                    onClick={handleClose}
                    sx={{height:'30px'}}
                    >
                        fechar
                    </Button>
                </ButtonGroup>
            </div>

            {!editOpen &&
            <Button
            variant="contained"
            startIcon={<AddIcon />}
            size="small"
            onClick={() => {
                setEditOpen(true)
                setFormData({})
            }}
              sx={{ mb: 1 }}
            >
            Adicionar Usuário
            </Button>}

            {editOpen && (
                <Box
                    sx={{
                    bgcolor: "background.default",
                    p: 2,
                    borderRadius: 1,
                    mb: 2,
                    }}
                >
                    <Typography sx={{color:'text.primary'}} variant="subtitle1" gutterBottom>
                    {/* {editIndex !== null ? "Editar Usuário" : "Adicionar Usuário"} */}
                    </Typography>
                    <Box display="flex" flexDirection="column" gap={2}>
                        <div
                        style={{
                            width:'100%',
                            display:'flex',
                            justifyContent:'space-between'
                        }}>
                            <TextField
                                label="Número (país + DDD)"
                                name="numero"
                                value={formData.numero}
                                onChange={handleChange}
                                fullWidth
                                size="small"
                                helperText={'Exemplo: 55 21 912345678'}
                            />

                            <TextField
                                label="Nome"
                                name="nome"
                                value={formData.nome}
                                onChange={handleChange}
                                fullWidth
                                size="small"
                            />
                        </div>

                        <div
                        style={{
                            width:'100%',
                            display:'flex',
                            justifyContent:'space-between'
                        }}
                        >
                            <TextField
                            label="Tempo"
                            name="time"
                            value={formData.time}
                            onChange={handleChange}
                            fullWidth
                            size="small"
                            />
                            <TextField
                                label="Tipo de Tempo"
                                name="timetype"
                                value={formData.timetype}
                                onChange={handleChange}
                                select
                                fullWidth
                                size="small"
                                SelectProps={{ native: true }}
                            >
                                <option value="s">Segundos</option>
                                <option value="m">Minutos</option>
                                <option value="h">Horas</option>
                            </TextField>
                        </div>
                    
                    </Box>

                    <Box display="flex" justifyContent="space-between" mt={2}>
                    <Button variant="outlined" onClick={() => setEditOpen(false)} size="small">
                        Cancelar
                    </Button>
                    <Button variant="contained" onClick={handleSave} size="small" color='success'>
                        concluir
                    </Button>
                    </Box>
                </Box>
            )}

            <TableContainer component={Paper} sx={{ mb: 2 }}>
            <Table size="small">
                <TableHead>
                <TableRow>
                    <TableCell>Número</TableCell>
                    <TableCell>Nome</TableCell>
                    <TableCell>Fim</TableCell>
                    <TableCell>Tempo</TableCell>
                    <TableCell>Ações</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {users.map((user, index) => (
                    <TableRow key={index}>
                    <TableCell>{user.numero}</TableCell>
                    <TableCell>{user.nome || 'Bloqueio Operacional'}</TableCell>
                    <TableCell>
                        {new Date(user.timestamp).toLocaleString('pt-BR')}
                    </TableCell>
                    <TableCell>
                        {user.time} {user.timetype}
                    </TableCell>
                    <TableCell>
                        <IconButton
                        size='small'
                        onClick={() => handleEdit(index)}
                        >
                            <EditIcon color='primary' />
                        </IconButton>
                        <IconButton
                        size='small'
                        onClick={() => handleDelete(index)}
                        >
                            <DeleteIcon color='error'/>
                        </IconButton>
                        {/* <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Detalhes</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                            Mensagem: {user.message || "Sem mensagem"}
                            </Typography>
                            <Box display="flex" justifyContent="flex-end" gap={1} mt={1}>
                            <IconButton onClick={() => handleEdit(index)} size="small">
                                <EditIcon fontSize="small" />
                            </IconButton>
                            <IconButton onClick={() => handleDelete(index)} size="small">
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                            </Box>
                        </AccordionDetails>
                        </Accordion> */}
                    </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
        </Box>
        </Modal>
    );
});

export default PauseUsers;
