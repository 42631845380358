import { useContext, useEffect, useRef, useState } from "react"
import { LoadingContext, SizeContext, UserContext } from "../../../context/handleContext"
import axios from "axios"
import { api } from "../../../utils/defaults"
import { toast } from "react-toastify"
import Table from '../../table/virtualized'

import SyncIcon from '@mui/icons-material/Sync';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CloseIcon from '@mui/icons-material/Close';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';

import { Modal, Box, Typography, Button, TextField, Checkbox, FormControlLabel, CircularProgress } from '@mui/material';

import useInterval from "use-interval"
import Confirm from "../../../utils/confirm"
import File from "../../../utils/file"
import defs from "./defs"
import Docs from "./docs"

export default function Action (props) {
    const [loadingContext, setLoadingContext] = useContext(LoadingContext)
    const [size, setSize] = useContext(SizeContext)
    const [user, setUser] = useContext(UserContext)
    const [state, setState] = useState(props.state)
    useEffect(() => setState(props.state), [props.state])

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState([])
    const [selected, setSelected] = useState([])
    const [item, setItem] = useState({allon_queue_edit_open: false})

    const updateState = () => {
        if ( !state.actionId ) return 
        const controller = new AbortController()
        
        axios
        .get(`${api}/api/manage/integration/queue/${state.actionId}/state`,{
            headers: {
                Authorization: user.token
            },
            signal: controller.signal
        })
        .then(response => setState({ ...response.data, actionId: state.actionId }))
        .catch(err => toast.error(err?.response?.data?.message || err?.response?.data))

        return controller
    }

    const handleSave = () => {
        setLoadingContext(true)
        axios
        .put(`${api}/api/manage/integration/queue/${state.actionId}/${item.allon_queue_key}`,{
            ...item,
            allon_queue_edit_open: undefined,
            allon_queue_key: undefined
        },{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            toast.success('Salvo')
            update()
        })
        .catch(err => toast.error(err?.response?.data?.message || err?.response?.data))
        .finally(() => setLoadingContext(false))
    }

    const update = () => {
        if ( !state.actionId ) return 
        const controller = new AbortController()
        setLoading(true)
        axios
        .get(`${api}/api/manage/integration/queue/${state.actionId}`,{
            headers: {
                Authorization: user.token
            },
            signal: controller.signal
        })
        .then(response => setList((response?.data || []).map(e => ({
            ...e.item,
            key: e.key,
            id: e.key,
            id_original: e.item.id,
            item: e.item,
        }))))
        .catch(err => toast.error(err?.response?.data?.message || err?.response?.data))
        .finally(() => setLoading(false))

        return controller
    }

    const sendState = () => {
        // if ( state.isAdding ) {
            
        //     confirmRef.current?.confirm({ text: 'Existem ítens sendo inseridos na sua fila, iniciar o processamento agora poderá gerar duplicidade, deseja iniciar mesmo assim ?' })
        //     .then(response => {
        //         setLoadingContext(true)
        //         axios
        //         .post(`${api}/api/manage/integration/queue/${state.actionId}/state`,{
        //             pause: !state.pause
        //         },{
        //             headers: {
        //                 Authorization: user.token
        //             }
        //         })
        //         .then(response => updateState())
        //         .catch(err => toast.error(err?.response?.data?.message || err?.response?.data))
        //         .finally(() => setLoadingContext(false))
        //     })
        //     .catch(err => {})

        // } else {
        //     setLoadingContext(true)
        //     axios
        //     .post(`${api}/api/manage/integration/queue/${state.actionId}/state`,{
        //         pause: !state.pause
        //     },{
        //         headers: {
        //             Authorization: user.token
        //         }
        //     })
        //     .then(response => updateState())
        //     .catch(err => toast.error(err?.response?.data?.message || err?.response?.data))
        //     .finally(() => setLoadingContext(false))
        // }
        setLoadingContext(true)
        axios
        .post(`${api}/api/manage/integration/queue/${state.actionId}/state`,{
            pause: !state.pause
        },{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => updateState())
        .catch(err => toast.error(err?.response?.data?.message || err?.response?.data))
        .finally(() => setLoadingContext(false))
    }

    const removeSelected = () => {
        setLoadingContext(true)
        axios
        .post(`${api}/api/manage/integration/queue/${state.actionId}/remove`,selected,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            setSelected([])
            toast.success('Removido')
            update()
        })
        .catch(err => toast.error(err?.response?.data?.message || err?.response?.data))
        .finally(() => setLoadingContext(false))
        updateState()
    }

    const remove = () => {
        const filtered = list.filter(({key}) => !selected.find(e => e === key))
        setLoadingContext(true)
        axios
        .delete(`${api}/api/manage/integration/queue/${state.actionId}/${item.key}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            setList((response?.data || []).map(e => ({
                ...e.item,
                key: e.key,
                id: e.key,
                id_original: e.item.id,
                item: e.item,
            })))
            setItem({allon_queue_edit_open:false})
            toast.success('Removido')
        })
        .catch(err => toast.error(err?.response?.data?.message || err?.response?.data))
        .finally(() => setLoadingContext(false))
        updateState()
    }

    const removeAll = () => {
        const filtered = list.filter(({key}) => !selected.find(e => e === key))
        setLoadingContext(true)
        axios
        .delete(`${api}/api/manage/integration/queue/${state.actionId}`,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            update()
            toast.success('Removido')
        })
        .catch(err => toast.error(err?.response?.data?.message || err?.response?.data))
        .finally(() => setLoadingContext(false))
        updateState()
    }

    const importData = (data) => {
        setLoadingContext(true)
        axios
        .post(`${api}/api/manage/integration/queue/${state.actionId}`,data,{
            headers: {
                Authorization: user.token
            }
        })
        .then(response => {
            toast.success('Enviado para fila, isso pode demorar um pouco...')
            fileRef.current?.close()
            updateState()
            update()
        })
        .catch(err => toast.error(err?.response?.data?.message || err?.response?.data))
        .finally(() => setLoadingContext(false))
        updateState()
    } 

    useEffect(() => {
        const controller = update()
        const controller2 = updateState()

        return () => {
            controller?.abort()
            controller2?.abort()
        }
    },[state.actionId])

    useInterval(() => {
        const controller = update()
        const stateController = updateState()

        return () => {
            controller.abort()
            stateController.abort()
        }
    }, 10000)

    const confirmRef = useRef()
    const fileRef = useRef()
    const docsRef = useRef()

    return (
        <div>
            <Confirm ref={confirmRef} />

            <Modal
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
            open={item.allon_queue_edit_open}
            onClose={() => setItem({ allon_queue_edit_open: false })}
            >
                <Box
                    sx={{
                    p: 3,
                    borderRadius: 2,
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'dark' ? 'background.paper' : '#fff',
                    width: '90%',
                    maxWidth: '500px',
                    boxShadow: 3,
                    }}
                >
                    <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mb: 2,
                    }}
                    >
                    <Button
                        color="error"
                        variant="outlined"
                        startIcon={<DeleteIcon />}
                        onClick={() => {
                        confirmRef.current
                            ?.confirm()
                            .then(remove)
                            .catch(() => {});
                        }}
                    >
                        Excluir
                    </Button>
                    <Button
                        color="error"
                        variant="contained"
                        startIcon={<CloseIcon />}
                        onClick={() => setItem({ allon_queue_edit_open: false })}
                    >
                        Fechar
                    </Button>
                    </Box>

                    <Typography
                    variant="h6"
                    sx={{
                        mb: 2,
                        textAlign: 'center',
                        color: (theme) =>
                        theme.palette.mode === 'dark' ? 'text.primary' : 'text.secondary',
                    }}
                    >
                    Editar Propriedades
                    </Typography>

                    <Box
                    component="form"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        maxHeight: 'calc(90vh - 200px)',
                        overflowY:"auto",
                        paddingTop:'10px'
                    }}
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSave()
                    }}
                    >
                        {Object.entries(item)
                            .filter(
                            ([key]) =>
                                !['allon_queue_edit_open','allon_queue_key'].includes(key)
                            )
                            .map(([key, value]) => (
                            <Box key={key}>
                                {typeof value === 'boolean' ? (
                                <FormControlLabel
                                    sx={{color:'text.primary'}}
                                    control={
                                    <Checkbox
                                        checked={value}
                                        onChange={(e) =>
                                        setItem((prev) => ({
                                            ...prev,
                                            [key]: e.target.checked,
                                        }))
                                        }
                                    />
                                    }
                                    label={key}
                                />
                                ) : <></>}

                                {typeof value === 'number' ? (
                                <TextField
                                    label={key}
                                    type="number"
                                    value={value}
                                    onChange={(e) =>
                                    setItem((prev) => ({
                                        ...prev,
                                        [key]: parseFloat(e.target.value) || 0,
                                    }))
                                    }
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                />
                                ) : <></>}

                                {typeof value === 'string' ? (
                                <TextField
                                    label={key}
                                    type="text"
                                    value={value}
                                    onChange={(e) =>
                                    setItem((prev) => ({
                                        ...prev,
                                        [key]: e.target.value,
                                    }))
                                    }
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                />
                                ) : <></>}

                                {Array.isArray(value) ? (
                                <TextField
                                    label={`${key} (Array)`}
                                    type="text"
                                    value={value.join(', ')}
                                    onChange={(e) =>
                                    setItem((prev) => ({
                                        ...prev,
                                        [key]: e.target.value.split(',').map((v) => v.trim()),
                                    }))
                                    }
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    helperText="Separe os itens com vírgulas."
                                />
                                ) : <></>}

                                {value && typeof value === 'object' && !Array.isArray(value) ? (
                                <TextField
                                    label={`${key} (JSON)`}
                                    type="text"
                                    value={JSON.stringify(value, null, 2)}
                                    onChange={(e) => {
                                    try {
                                        const parsedValue = JSON.parse(e.target.value);
                                        setItem((prev) => ({
                                        ...prev,
                                        [key]: parsedValue,
                                        }));
                                    } catch {
                                        // console.error('Formato inválido de JSON.');
                                    }
                                    }}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    helperText="Digite um objeto JSON válido."
                                />
                                ) : <></>}
                            </Box>
                            ))}

                        <Button type="submit" variant="contained" color="primary" fullWidth>
                            Salvar Alterações
                        </Button>
                    </Box>
                </Box>
            </Modal>


            <div
            style={{
                display:'flex',
                justifyContent:'space-between',
                width:'100%',
                margin:'10px 0px',
                flexWrap:'wrap'
            }}
            >
                <div
                style={{
                    display:'flex',
                    justifyContent:'space-between',
                    width: size.width <= 500 && '100%'
                }}
                >
                    <Button
                    variant='outlined'
                    startIcon={loading ? <CircularProgress size={20} /> : <SyncIcon />}
                    size='small'
                    sx={{marginRight:size.width > 500 && '10px'}}
                    onClick={() => {
                        updateState()
                        update()
                    }}
                    disabled={loading}
                    >
                        Atualizar
                    </Button>
                    <Button
                    variant='outlined'
                    startIcon={state.pause ? <PlayArrowIcon /> : <PauseIcon />}
                    size='small'
                    // disabled={state.isAdding}
                    onClick={sendState}
                    sx={{marginRight:size.width > 500 && '10px'}}
                    >
                        {state.pause ? 'Retomar' : 'Pausar'}
                    </Button>
                    <Button
                    variant='outlined'
                    startIcon={<DeleteSweepIcon />}
                    size='small'
                    disabled={!list.length}
                    onClick={() => {
                        confirmRef.current?.confirm()
                        .then(removeAll)
                        .catch(()=>{})
                    }}
                    color='error'
                    >
                        limpar fila
                    </Button>
                </div>

                <div
                style={{
                    display:'flex',
                    justifyContent:'space-between',
                    width: size.width <= 500 && '100%'
                }}
                >
                    <Docs 
                    field_list={defs[state.actionId]?.field}
                    url_params={defs[state.actionId]?.api?.url_params}
                    query_params={defs[state.actionId]?.api?.query_params}
                    url={defs[state.actionId]?.api?.url}
                    method={defs[state.actionId]?.api?.method}
                    description={defs[state.actionId]?.api?.description}
                    header={defs[state.actionId]?.api?.header}
                    ref={docsRef}
                    />

                    <File 
                    ref={fileRef}
                    buttonProps={{
                        style:{ marginRight:size.width > 500 && '10px' },
                        variant:'outlined', 
                        color:"success",
                        size:'small',
                        startIcon: <UploadFileIcon />
                    }}
                    downloadLayoutName={`layout_importacao_${state.actionId}.csv`}
                    showDownloadLayoutButton={true}
                    downloadLayoutButtonProps={{
                        sx:{marginRight:size.width > 500 && '10px'}
                    }}
                    buttonText='Enviar arquivo'
                    onImport={data => importData(data)}
                    fieldList={defs[state.actionId]?.field}
                    params={defs[state.actionId]?.params}
                    layout={defs[state.actionId]?.field.map(({field}) => (field))}
                    sync={false}
                    />


                    <Button
                    variant='outlined'
                    startIcon={<DeleteForeverIcon />}
                    color='error'
                    size='small'
                    disabled={!selected.length}
                    onClick={() => {
                        confirmRef.current?.confirm()
                        .then(removeSelected)
                        .catch(()=>{})
                    }}
                    >
                        Remover selecionados
                    </Button>
                </div>
            </div>

            <Table 
            list={list}
            pageSizeOptions={[20, 50, 100]}
            check={true}
            page={0}
            onCheck={(a,b,c) => setSelected(c)}
            onRowClick={(data, event) => {
                if (event.target.localName === 'button') return
                setItem({
                    ...data.row.item, 
                    allon_queue_edit_open: true,
                    allon_queue_key: data.row.key
                })
            }}
            density='compact'
            selected={selected}
            disableRowSelectionOnClick={true}
            autoHeight={false}
            initialState = {{
                columns: {
                    columnVisibilityModel: {
                        id: false,
                        id_original: false
                    }
                },
                pagination: {
                    paginationModel: { page: 0, pageSize: 20 },
                }
            }}
            toolbar={true}
            sx={{
                height:'100%'
            }}
            height={`calc(100vh - ${size.width <= 500 ? '290px' : '240px'})`}
            />
        </div>
    )
}