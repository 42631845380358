import { 
    AppBar, 
    Box, 
    Tabs,
    Typography,
    useTheme,
    Tab,
    IconButton,
    CircularProgress
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import PropTypes from 'prop-types';
import { IntegradorContext, ApplicationContext, DeviceContext } from "./context";
import axios from "axios";
import { api } from "../../../utils/defaults";
import { UserContext } from "../../../context/handleContext";
import { toast } from "react-toastify";
import Action from "./action";
import SyncIcon from '@mui/icons-material/Sync';
import defs from './defs'

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 0 }}>
            {children}
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
children: PropTypes.node,
index: PropTypes.number.isRequired,
value: PropTypes.number.isRequired,
};
  
function a11yProps(index) {
return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
};
}

export default function Integration (props) {
    const [user, setUser] = useContext(UserContext)
    const [index, setIndex] = useState(0)
    const [loading, setLoading] = useState(false)
    const [actions, setActions] = useState([])

    const getActions = () => {
        const controller = new AbortController()
        setLoading(true)
        axios
        .get(`${api}/api/manage/integration/queue`,{
            headers: {
                Authorization: user.token
            },
            signal: controller.signal
        })
        .then(response => setActions(response?.data || []))
        .catch(err => toast.error(err?.response?.data?.message || err?.response?.data))
        .finally(() => setLoading(false))
        return controller
    }

    useEffect(() => {
        const getActionController = getActions()

        return () => {
            getActionController.abort()
        }
    },[])

    const theme = useTheme()

    return (
        <Box 
        sx={{ 
            bgcolor: 'background.paper', 
            width: '100%',
            // padding: '5px !important',
            margin:'0px',
            overflowY:'hidden'
        }}>
            <AppBar 
            sx={{
                backgroundColor:theme.palette.mode==='light' && 'black'
            }}
            position="static">
                <Tabs
                value={index}
                onChange={(event, newIndex) => setIndex(newIndex)}
                indicatorColor="primary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
                >
                    {actions.filter(({actionId}) => defs[actionId]).map((e, index) => (<Tab label={e.actionId} {...a11yProps(index)} />))}
                    <IconButton
                    onClick={getActions}
                    disabled={loading}
                    >
                        {loading ? <CircularProgress size={20} /> : <SyncIcon />}
                    </IconButton>
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={index}
                onChangeIndex={newIndex => setIndex(newIndex)}
                enableMouseEvents={false}
            >
                {actions.filter(({actionId}) => defs[actionId]).map((e, i) => (
                <TabPanel 
                value={index} index={i} dir={theme.direction}>
                    <Action state={e} />
                </TabPanel>
                ))}
            </SwipeableViews>
        </Box>
    )
}